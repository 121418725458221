import React from "react"
import {graphql} from "gatsby"
import AboutUs from "../templates/pages/o-nas";

export default ({data}) => <AboutUs isEn={false} data={data}/>

export const result = graphql`
    query People {
        allPeopleYaml {
            edges {
                node {
                    title
                    image {
                        url
                    }
                    person_function
                    description
                    type
                }
            }
        }
    }
`
